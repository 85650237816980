// ----------------------------------------------------
// page ready check:
// ----------------------------------------------------

// run initialization after page has loaded:
var readyStateCheckInterval = setInterval(function() {
		if (document.readyState === "complete") {
		  clearInterval(readyStateCheckInterval);
		  cookiesEuBanner(cookieValues);
		}
}, 10);

// ----------------------------------------------------
// variables:
// ----------------------------------------------------

// set cookie values:
// cookie name (has to match toggle button ids)
// initial value
// ability to change the value
// timeout in milliseconds until the cookie is not valid anymore (one year is 31536000000)
// execute this code if the value is true
// delete these external cookies if the value is false
var cookieValues = [
		{
				name: "cookies-eu-necessary",
				value: true,
				fixed: true,
				timeout: 31536000000,
				code: function() {
						//console.log("necessary code executed");
				},
				deleteCookieNames: []
		},
		{
				name: "cookies-eu-statistics",
				value: false,
				fixed: false,
				timeout: 31536000000,
				code: function() {
						//console.log("statistics code executed");
						
						googleAnalytics();
				},
				deleteCookieNames: ["_ga", "_gid", "_gat_gtag_UA_147577970_1"]
		},
		{
				name: "cookies-eu-comfort",
				value: false,
				fixed: false,
				timeout: 31536000000,
				code: function() {
						//console.log("comfort code executed");
				},
				deleteCookieNames: []
		},
		{
				name: "cookies-eu-personalisation",
				value: false,
				fixed: false,
				timeout: 31536000000,
				code: function() {
						//console.log("personalisation code executed");
				},
				deleteCookieNames: []
		}
];

// ----------------------------------------------------
// functions for individual cookies:
// ----------------------------------------------------

function googleAnalytics() {
	var script = document.createElement('script');
	
	script.onload = function () {
	  window.dataLayer = window.dataLayer || [];
	  function gtag() {
		  	dataLayer.push(arguments);
		 }
	  gtag('js', new Date());
	  gtag('config', 'UA-147577970-1', {'anonymize_ip': true});
	};
	
	script.src = "https://www.googletagmanager.com/gtag/js?id=UA-147577970-1";
	
	document.head.appendChild(script);
}

// ----------------------------------------------------
// code:
// ----------------------------------------------------

function cookiesEuBanner(cookieValues) {
		
		// get banner:
		var banner = document.getElementById("cookies-eu-banner");
		
		// only run if banner is available on the page:
		if (banner) {
			
				// get save and revoke buttons
				var saveButton = document.getElementById("cookies-eu-save");
				var saveAllButton = document.getElementById("cookies-eu-save-all");
				var revokeButton = document.getElementById("cookies-eu-revoke");
				
				// destroy banner element for DoNotTrack and bots and exit main code:
				if (botCheck(banner)) {
						return false;
				}
				
				// set event listeners:
				cookieValues.forEach(function(e) {
						if (e.fixed === false) {
								toggleClassListener(document.getElementById(e.name), banner);
						} else {
								document.getElementById(e.name).setAttribute("disabled", "");
						}
				})
				
				saveListener(saveButton, banner);
				saveAllListener(saveAllButton, banner);
				revokeListener(revokeButton, banner);
				
				// only show banner if cookies dont exist:
				if (hasConsented(cookieValues) == true) {
						hideBanner(banner);
				} else {
						showBanner(banner);
				}
		}
}

function botCheck(banner) {
		var bots = /bot|crawler|spider|crawling/i;
		var isBot = bots.test(navigator.userAgent);
		
		var doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack;
		var isToTrack = (doNotTrack !== null && doNotTrack !== undefined) ? (doNotTrack && doNotTrack !== 'yes' && doNotTrack !== 1 && doNotTrack !== '1') : true;
		
		if (isBot || !isToTrack) {
		  removeBanner(banner);
		  return true;
		}
}

function toggleClassListener(button, banner) {
		if (button && banner) {
				button.addEventListener("click", function() {
						if (button.classList.contains("consented")) {
								button.classList.remove("consented");
								
								cookieValues.forEach(function(e) {
										if (e.name === button.id) {
												e.value = false;
										}
								})
						} else {
								button.classList.add("consented");
								
								cookieValues.forEach(function(e) {
										if (e.name === button.id) {
												e.value = true;
										}
								})
						}
				})
		}
}

function saveListener(button, banner) {
		if (button && banner) {
				button.addEventListener("click", function() {
						save(false);
						hideBanner(banner);
				})
		}
}

function saveAllListener(button, banner) {
		if (button && banner) {
				button.addEventListener("click", function() {
						save(true);
						setTimeout(function() {
								hideBanner(banner);
						}, 1200)
				})
		}
}

function revokeListener(button, banner) {
		if (button && banner) {
				button.addEventListener("click", function() {
						showBanner(banner);
				})
		}
}

function save(all) {
		var i = 0;
		
		timeoutLoop();
		
		function timeoutLoop () {
				if (all === true) {
						setCookie(cookieValues[i].name, true, cookieValues[i].timeout);
						
						cookieValues[i].code();
						
						setTimeout(function () {
								document.getElementById(cookieValues[i].name).classList.add("consented");
								
		      i++;
		      if (i < cookieValues.length) {
		        timeoutLoop();
		      }
			   }, 200)
				} else {
						setCookie(cookieValues[i].name, cookieValues[i].value, cookieValues[i].timeout);
						
      if (getCookie(cookieValues[i].name) === true) {
								cookieValues[i].code();
						} else {
								cookieValues[i].deleteCookieNames.forEach(function(e) {
										deleteCookie(e);
								})
						}
						
						i++;
      if (i < cookieValues.length) {
        timeoutLoop();
      }
				}
		}
}

function hasConsented(cookieValues) {
		var atLeastOneCookie = false;
		
		cookieValues.forEach(function(e) {
				if (getCookie(e.name) === true) {
						atLeastOneCookie = true;
						
						e.code();
				} else if (getCookie(e.name) === false) {
						atLeastOneCookie = true;
				}
		})
		
		if (atLeastOneCookie === true) {
				return true;
		} else {
				return false
		}
}

function hideBanner(banner) {
		if (banner) {
				banner.classList.remove("is-active");
				
				//body.classList.remove("no-scrollbar");
				
				document.querySelector("body").style = "overflow-y: auto";
		}
}

function showBanner(banner) {
		if (banner) {
				cookieValues.forEach(function (e) {
						if (getCookie(e.name) === true) {
								e.value = true;
						} else if (getCookie(e.name) === false) {
								e.value = false;
						}
				})
				
				applyClasses(cookieValues);
				
				banner.classList.add("is-active");
				
				//body.classList.add("no-scrollbar");
				//document.querySelector("body").classList.add("no-scrollbar");
				
				document.querySelector("body").style = "overflow-y: hidden";
		}
}

function removeBanner(banner) {
		if (banner && banner.parentNode) {
				banner.parentNode.removeChild(banner);
		}
}

function applyClasses(cookieValues) {
	 cookieValues.forEach(function(e) {
		 	if (e.value === true) {
			 		document.getElementById(e.name).classList.add("consented");
		 	}
	 })
}

function setCookie(name, value, timeout) {
  var currentDate = new Date();
  var expirationDate = new Date(currentDate.getTime() + timeout);
  
  document.cookie = name + '=' + value + '; expires=' + expirationDate + '; path=/';
}

function getCookie(name) {
		var isCookieSetTo = function(value) {
    return document.cookie.indexOf(name + '=' + value) > -1;
  };
		
  if (isCookieSetTo('true')) {
    return true;
  } else if (isCookieSetTo('false')) {
    return false;
  } else return null;
}

function deleteCookie(name) {
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
}