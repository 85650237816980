import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

require('foundation-sites');
//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

//import 'tablesaw/dist/tablesaw.jquery';
import AOS from 'aos';
import slick from 'slick-carousel';

require('./lib/cookies-eu-banner');

$(document).foundation();


//****************************
// AOS
//****************************

AOS.init({
	duration: 1000,
});

//*******************************
// Burger (jQuery) 
//*******************************

$("#offCanvas").on("opened.zf.offcanvas", function(e) {
 $('#menuButton').addClass('is-active');
});

$("#offCanvas").on("closed.zf.offcanvas", function(e) {
 $('#menuButton').removeClass('is-active');
});

//*********************************
// on changed mediaquery (jQuery) 
//*********************************

$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
  if (newSize == 'large' || newSize == 'xlarge' || newSize == 'xxlarge'){
   $('#offCanvas').foundation('close');
  }
  if (newSize == 'small' || newSize == 'xsmall' || newSize == 'medium' || newSize == 'xmedium'){
   //$('#offCanvas').foundation('close');
  }
	//console.log(newSize);
});


//*********************************
// slick-slider
//*********************************

//slick-responsive
var $optsResponsive = {
	dots: true,
	arrows: false,
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 3,
	autoplay: true,
	autoplaySpeed: 10000,
 pauseOnFocus: false,
 pauseOnHover: false,
	responsive: [
   {
    breakpoint: 1599,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  },
   {
    breakpoint: 1439,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3
    }
  },
 {
    breakpoint: 1199,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3
    }
  },
 {
    breakpoint: 1023,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  },
  {
    breakpoint: 639,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
 ]
}

// callback before slide change
$('.slick-responsive').on('beforeChange', function(event, slick, currentSlide, nextSlide){
 //console.log('before ' + nextSlide);

});

// callback after slide change
$('.slick-responsive').on('afterChange', function(event, slick, currentSlide, nextSlide){
 //console.log('after ' + currentSlide);
});

$('.slick-responsive').slick($optsResponsive);

//slick-single-slide
var $optsSlide = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  pauseOnFocus: false,
  pauseOnHover: true,
  pauseOnDotsHover: false
}
$('.slick-single-slide').slick($optsSlide);

//slick-single-fade
var $optsFade = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  fade: true,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  pauseOnFocus: false,
  pauseOnHover: true,
  pauseOnDotsHover: false
}
$('.slick-single-fade').slick($optsFade);

